import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const state = {
    meta: {
        count: 0,
        currentPage: 0
    },
    uiFlags: {
        isFetchingTabs: false,
        isCreatingTab: false,
        isDeletingTab: false,

        isFetchingItems: false,
        isFetchingItem: false,
        isCreatingItem: false,
    },    
    tabRecords: [],
    itemRecords: []
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};