import types from '../../mutation-types.js';
import BotLearningTabsAPI from '../../../api/botLearningTabs.js';
import BotLearningItemAPI from '../../../api/botLearningItems.js';
import { frontendURL } from '../../../helper/URLHelper.js';
import { DuplicateTabException } from '../../../../shared/helpers/CustomErrors.js';

const createTabUrl = (accountId, tab) => {
  return Object.assign(tab, {
    toState: frontendURL(`accounts/${accountId}/bot-learning/${tab.id}/list`),
  });
};

export const actions = {
  getTabs: async ({ commit }, data) => {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isFetchingTabs: true });
    try {
      const {
        data: { items, published_at },
      } = await BotLearningTabsAPI.get();
      items.map(tab => createTabUrl(data.accountId, tab));
      commit(types.CLEAR_BOT_LEARNING_TABS);
      commit(types.SET_BOT_LEARNING_TABS, items);
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isFetchingTabs: false });
    } catch (error) {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isFetchingTabs: false });
    }
  },

  createTab: async function createTab({ commit }, data) {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreating: true });
    try {
      const response = await BotLearningTabsAPI.create({
        title: data.title,
      });
      const newTab = createTabUrl(data.accountId, response.data);
      commit(types.ADD_BOT_LEARNING_TAB, newTab);
      return newTab;
    } catch (error) {
      if (error.response?.status === 409) {
        throw new DuplicateTabException();
      } else {
        throw new Error();
      }
    } finally {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreating: false });
    }
  },

  deleteTab: async ({ commit }, id) => {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isDeletingTab: true });
    try {
      await BotLearningTabsAPI.delete(id);
      commit(types.DELETE_BOT_LEARNING_TAB, id);
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isDeletingTab: false });
    } catch (error) {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isDeletingTab: false });
      throw new Error(error);
    }
  },

  editTab: async ({ commit }, data) => {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreatingTab: true });
    try {
      const {
        data: { id, title },
      } = await BotLearningTabsAPI.update(data.tabId, { title: data.title });
      commit(types.UPDATE_BOT_LEARNING_TAB, {
        id,
        title,
      });
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreatingTab: false });
      return title;
    } catch (error) {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreatingTab: false });
      if (error.response?.status === 409) {
        throw new DuplicateTabException();
      } else {
        throw new Error(error);
      }
    }
  },

  getItems: async ({ commit }, data) => {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isFetchingItems: true });
    try {
      const {
        data: { items, total_count },
      } = await BotLearningItemAPI.get(data);
      commit(types.CLEAR_BOT_LEARNING_ITEMS);
      commit(types.SET_BOT_LEARNING_ITEMS, items);
      commit(types.SET_BOT_LEARNING_ITEMS_META, {
        total_count,
        currentPage: data.page,
      });
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isFetchingItems: false });
    } catch (error) {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isFetchingItems: false });
    }
  },

  createItem: async function createItem({ commit }, data) {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreatingItem: true });
    try {
      await BotLearningItemAPI.create(data);
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreatingItem: false });
    }
  },

  editItem: async ({ commit }, data) => {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreatingItem: true });
    try {
      const response = await BotLearningItemAPI.update(data.item_id, data);
      commit(types.UPDATE_BOT_LEARNING_ITEM, response.data);
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreatingItem: false });
    } catch (error) {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isCreatingItem: false });
      throw new Error(error);
    }
  },

  getItem: async ({ commit }, data) => {
    commit(types.SET_BOT_LEARNING_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await BotLearningItemAPI.getSingleItem(data);
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isFetchingItem: false });
      return response.data;
    } catch (error) {
      commit(types.SET_BOT_LEARNING_UI_FLAG, { isFetchingItem: false });
      throw new Error(error);
    }
  },
};
