<template>
  <div class="w-full flex flex-row">
    <div v-if="hasTabs" class="flex flex-col h-full" :class="wrapClass">
      <bot-learning-header
        :header-title="header"
        :has-item-records="hasItemRecords"
        :search-query="searchQuery"
        @on-toggle-create-item="onToggleCreateItem"
        @open-edit-tab="openEditTabModal"
        @open-delete-tab="openDeleteTabModal"
        @on-input-search="onInputSearch"
        @on-search-submit="onSearchSubmit"
      />
      <bot-learning-items-table
        :records="itemRecords"
        :is-loading="uiFlags.isFetchingItems"
        @open-edit-item="onToggleEditItem"
      />
      <table-footer
        class="border-t border-slate-75 dark:border-slate-700/50"
        :current-page="Number(meta.currentPage)"
        :total-count="meta.count"
        :page-size="pageSize"
        @page-change="onPageChange"
      />
      <add-bot-learning-item
        :tab-id="tabId"
        :show="showCreateItemModal"
        @cancel="onToggleCreateItem"
        @on-success="onNewItemCreated"
      />
      <edit-bot-learning-item
        :item-id="editableItemId"
        :tab-id="tabId"
        :show="showEditItemModal"
        @cancel="onToggleEditItem"
      />
      <woot-modal :show.sync="showEditTabModal" :on-close="hideEditTabModal">
        <edit-tab-modal :prefill="tabInfo" @close="hideEditTabModal" />
      </woot-modal>
    </div>
    <div v-else class="flex flex-col w-full items-center justify-center">
      <empty-state :title="$t('BOT_LEARNING_PAGE.TABS.EMPTY')" />
    </div>
    <woot-modal :show.sync="showDeleteTabModal" :on-close="hideDeleteTabModal">
      <delete-tab-modal
        :tab-id="tabId"
        :has-item-records="hasItemRecords"
        @close="hideDeleteTabModal"
        @go-to-previous-tab="goToPreviousTab"
      />
    </woot-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BotLearningItemsTable from './BotLearningItemsTable.vue';
import BotLearningHeader from './Header.vue';
import TableFooter from 'dashboard/components/widgets/TableFooter.vue';
import AddBotLearningItem from './AddBotLearningItem.vue';
import EditBotLearningItem from './AddBotLearningItem.vue';
import EditTabModal from './AddNewTab.vue';
import DeleteTabModal from './DeleteTab.vue';
import { BOT_LEARNING_ROUTES } from '../constants.js';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import rtlMixin from 'shared/mixins/rtlMixin';

const DEFAULT_PAGE = 1;
const PAGE_SIZE = 20;

export default {
  components: {
    BotLearningItemsTable,
    BotLearningHeader,
    TableFooter,
    AddBotLearningItem,
    EditBotLearningItem,
    EditTabModal,
    DeleteTabModal,
    EmptyState,
  },
  mixins: [rtlMixin],
  props: {
    tabId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      header: '',
      editableItemId: null,
      showCreateItemModal: false,
      showEditItemModal: false,
      showEditTabModal: false,
      showDeleteTabModal: false,
      searchQuery: '',
    };
  },
  computed: {
    ...mapGetters({
      itemRecords: 'botLearnings/getBotLearningItems',
      botLearningTabs: 'botLearnings/getBotLearningTabs',
      uiFlags: 'botLearnings/getUIFlags',
      meta: 'botLearnings/getMeta',
    }),
    pageParameter() {
      const selectedPageNumber = Number(this.$route.query?.page);
      return !Number.isNaN(selectedPageNumber) &&
        selectedPageNumber >= DEFAULT_PAGE
        ? selectedPageNumber
        : DEFAULT_PAGE;
    },
    wrapClass() {
      return this.showContactViewPane ? 'w-[75%]' : 'w-full';
    },
    hasTabs() {
      return this.botLearningTabs?.length > 0;
    },
    hasItemRecords() {
      return this.itemRecords.length > 0;
    },
    pageSize() {
      return PAGE_SIZE;
    },
    tabInfo() {
      if (this.tabId) {
        return {
          tabId: this.tabId,
          title: this.header,
        };
      }
      return null;
    },
  },
  watch: {
    tabId() {
      this.fetchBotLearnings(this.pageParameter);
      this.setHeader();
    },
    botLearningTabs() {
      const firstTab = this.redirectToFirstTab();
      this.setHeader(firstTab ? firstTab.title : null);
    },
  },
  mounted() {
    // redirect to the first tab by navigating to content management primary menu
    this.redirectToFirstTab();
    this.fetchBotLearnings(this.pageParameter);
  },
  methods: {
    fetchBotLearnings(page) {
      if (!this.tabId) return;

      const requestParams = {
        tabId: this.tabId,
        page: page,
        page_size: this.pageSize,
        q: this.searchQuery,
      };
      this.$store.dispatch('botLearnings/getItems', requestParams);
    },
    onPageChange(page) {
      this.fetchBotLearnings(page);
    },
    setHeader(title = '') {
      if (title) {
        this.header = title;
      } else if (this.hasTabs) {
        const tab = this.botLearningTabs.find(
          c => c.id.toString() === this.$store.state.route.params.tabId
        );
        if (tab) {
          this.header = tab.title;
        }
      }
    },
    redirectToFirstTab() {
      if (
        this.hasTabs &&
        this.$store.state.route.name === BOT_LEARNING_ROUTES.MAIN.NAME
      ) {
        const path = this.botLearningTabs[0].toState;
        this.$router.replace({ path });
        return this.botLearningTabs[0];
      }
      return null;
    },
    goToPreviousTab() {
      if (this.hasTabs) {
        const path =
          this.botLearningTabs[this.botLearningTabs.length - 1].toState;
        this.$router.replace({ path });
      } else {
        this.$router.replace({ name: BOT_LEARNING_ROUTES.MAIN.NAME });
      }
    },
    onToggleCreateItem() {
      this.showCreateItemModal = !this.showCreateItemModal;
    },
    onToggleEditItem(itemId) {
      this.showEditItemModal = !this.showEditItemModal;
      this.editableItemId = itemId;
    },
    openEditTabModal() {
      this.showEditTabModal = true;
    },
    hideEditTabModal(editedTab) {
      if (editedTab) {
        this.setHeader(editedTab);
      }
      this.showEditTabModal = false;
    },
    openDeleteTabModal() {
      if (!this.hasItemRecords) this.showDeleteTabModal = true;
    },
    hideDeleteTabModal() {
      this.showDeleteTabModal = false;
    },
    onInputSearch(event) {
      const newQuery = event.target.value;
      const refetchAllItems = !!this.searchQuery && newQuery === '';
      this.searchQuery = newQuery;
      if (refetchAllItems) {
        this.fetchBotLearnings(DEFAULT_PAGE);
      }
    },
    onSearchSubmit() {
      if (this.searchQuery) {
        this.fetchBotLearnings(DEFAULT_PAGE);
      }
    },
    onNewItemCreated() {
      this.searchQuery = '';
      this.fetchBotLearnings(DEFAULT_PAGE);
    },
  },
};
</script>
