import types from '../../mutation-types';
import Vue from 'vue';

export const mutations = {
  [types.SET_BOT_LEARNING_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  // Tabs

  [types.CLEAR_BOT_LEARNING_TABS]: $state => {
    Vue.set($state, 'tabRecords', []);
  },

  [types.SET_BOT_LEARNING_TABS]: ($state, data) => {
    $state.tabRecords = data;
  },

  [types.ADD_BOT_LEARNING_TAB]: ($state, data) => {
    $state.tabRecords.push(data);
  },

  [types.DELETE_BOT_LEARNING_TAB]: ($state, data) => {
    $state.tabRecords = $state.tabRecords.filter(r => r.id.toString() !== data);
  },

  [types.UPDATE_BOT_LEARNING_TAB]: ($state, data) => {
    let tab = $state.tabRecords.find(t => t.id === data.id);
    tab.title = data.title;
  },

  // Items

  [types.SET_BOT_LEARNING_ITEMS_META]: ($state, data) => {
    const { total_count, currentPage } = data;
    Vue.set($state.meta, 'count', total_count);
    Vue.set($state.meta, 'currentPage', currentPage);
  },

  [types.CLEAR_BOT_LEARNING_ITEMS]: $state => {
    Vue.set($state, 'itemRecords', []);
  },

  [types.SET_BOT_LEARNING_ITEMS]: ($state, data) => {
    $state.itemRecords = data;
  },
  [types.UPDATE_BOT_LEARNING_ITEM]: ($state, data) => {
    let itemRecordsCopy = $state.itemRecords.slice();
    let index = itemRecordsCopy.findIndex(t => t.id === data.id);
    itemRecordsCopy[index] = data;
    Vue.set($state, 'itemRecords', itemRecordsCopy);
  },
};
