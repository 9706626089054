<template>
  <header
    class="bg-white dark:bg-slate-900 border-b border-slate-50 dark:border-slate-800"
  >
    <div class="flex justify-between w-full py-2 px-4">
      <div class="flex items-center justify-center max-w-full min-w-[6.25rem]">
        <woot-sidemenu-icon />
        <h1
          class="m-0 text-xl text-slate-900 dark:text-slate-100 overflow-hidden whitespace-nowrap text-ellipsis my-0 mx-2"
        >
          {{ headerTitle }}
        </h1>
        <woot-button
          size="tiny"
          variant="clear"
          color-scheme="secondary"
          icon="edit"
          class="p-0 ml-2"
          :disabled="!headerTitle"
          @click="openEditTab"
        />
        <woot-button
          size="tiny"
          variant="clear"
          color-scheme="secondary"
          icon="delete"
          class="p-0 ml-2"
          :disabled="!headerTitle || hasItemRecords"
          @click="openDeleteTab"
        />
      </div>
      <div class="flex gap-2">
        <div
          class="max-w-[400px] min-w-[150px] flex items-center relative mx-2 search-wrap"
        >
          <div class="flex items-center absolute h-full left-2.5">
            <fluent-icon
              icon="search"
              class="h-5 leading-9 text-sm text-slate-700 dark:text-slate-200"
            />
          </div>
          <input
            type="text"
            :placeholder="$t('BOT_LEARNING_PAGE.SEARCH_INPUT_PLACEHOLDER')"
            class="item-search border-slate-100 dark:border-slate-600"
            :value="searchQuery"
            @keyup.enter="submitSearch"
            @input="inputSearch"
          />
          <woot-button
            :is-loading="false"
            class="clear"
            :class-names="searchButtonClass"
            @click="submitSearch"
          >
            {{ $t('BOT_LEARNING_PAGE.SEARCH_BUTTON') }}
          </woot-button>
        </div>
        <woot-button
          color-scheme="success"
          icon="add"
          @click="toggleCreateItem"
        >
          {{ $t('BOT_LEARNING_PAGE.CREATE.BUTTON_LABEL') }}
        </woot-button>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    hasItemRecords: {
      type: Boolean,
      default: false,
    },
    searchQuery: {
      type: String,
      default: '',
    },
  },
  computed: {
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
  },
  methods: {
    toggleCreateItem() {
      this.$emit('on-toggle-create-item');
    },
    openEditTab() {
      this.$emit('open-edit-tab');
    },
    openDeleteTab() {
      this.$emit('open-delete-tab');
    },
    submitSearch() {
      this.$emit('on-search-submit');
    },
    inputSearch(event) {
      this.$emit('on-input-search', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-wrap {
  .item-search {
    @apply pl-9 pr-[4.6rem] text-sm w-full h-[2.375rem] m-0;
  }

  .button {
    transition: transform 100ms linear;
    @apply ml-2 h-8 right-1 absolute py-0 px-2 opacity-0 -translate-x-px invisible;
  }

  .button.show {
    @apply opacity-100 translate-x-0 visible;
  }
}
</style>
