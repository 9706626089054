/* global axios */
import ApiClient from './ApiClient';

class BotLearningItemAPI extends ApiClient {
  constructor() {
    super('bot_learning_items', { apiVersion: 'v2', accountScoped: true });
  }

  get(data) {
    let requestURL = `${this.url}?tab_id=${data.tabId}&page=${data.page}&page_size=${data.page_size}&q=${data.q}`;
    return axios.get(requestURL);
  }

  getSingleItem(data) {
    let requestURL = `${this.url}/${data.itemId}`;
    return axios.get(requestURL);
  }

  create(data) {
    return super.create(data);
  }

  // edit(id, data) {
  //     return axios.put(`${this.url}/${id}`, data);
  // }
}

export default new BotLearningItemAPI();
