<template>
  <section
    class="bot-learning-table-wrap bg-white dark:bg-slate-900 flex-1 h-full overflow-hidden -mt-1"
  >
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 7.125rem)"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
    />
    <empty-state
      v-if="!isLoading && !records.length"
      :title="$t('BOT_LEARNING_PAGE.LIST.NO_BOT_LEARNING')"
    />
    <div v-if="isLoading" class="items-center flex text-base justify-center">
      <spinner />
      <span>{{ $t('BOT_LEARNING_PAGE.LIST.LOADING_MESSAGE') }}</span>
    </div>
  </section>
</template>

<script>
import { VeTable } from 'vue-easytable';

import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import rtlMixin from 'shared/mixins/rtlMixin';

export default {
  components: {
    EmptyState,
    Spinner,
    VeTable,
  },
  mixins: [rtlMixin],
  props: {
    records: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tableData() {
      if (this.isLoading) {
        return [];
      }
      return this.records;
    },
    columns() {
      return [
        {
          field: 'id',
          key: 'id',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.ID'),
          align: this.isRTLView ? 'right' : 'left',
        },
        {
          field: 'answer',
          key: 'answer',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.ANSWER'),
          align: this.isRTLView ? 'right' : 'left',
        },
        {
          field: 'question',
          key: 'question',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.SAMPLE_QUESTION'),
          align: this.isRTLView ? 'right' : 'left',
        },
        {
          field: 'disabled',
          key: 'disabled',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.STATUS'),
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            return row.disabled
              ? this.$t('BOT_LEARNING_PAGE.LIST.TABLE_BODY.DISABLE')
              : this.$t('BOT_LEARNING_PAGE.LIST.TABLE_BODY.ACTIVE');
          },
        },
        {
          field: 'published',
          key: 'published',
          title: this.$t('BOT_LEARNING_PAGE.LIST.TABLE_HEADER.PUBLISHED'),
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => {
            return row.published
              ? this.$t('BOT_LEARNING_PAGE.LIST.TABLE_BODY.PUBLISHED')
              : this.$t('BOT_LEARNING_PAGE.LIST.TABLE_BODY.UNPUBLISHED');
          },
        },
        {
          field: 'edit',
          key: 'edit',
          title: '',
          align: this.isRTLView ? 'right' : 'left',
          renderBodyCell: ({ row }) => (
            <woot-button
              size="tiny"
              variant="clear"
              color-scheme="secondary"
              icon="edit"
              class="p-0 ml-2"
              onClick={() => this.openEditItem(row.id)}
            />
          ),
        },
      ];
    },
  },
  methods: {
    openEditItem(itemId) {
      this.$emit('open-edit-item', itemId);
    },
  },
};
</script>

<style lang="scss" scoped>
.bot-learning-table-wrap::v-deep {
  .ve-table {
    @apply pb-8;
  }
  .row--user-block {
    @apply items-center flex text-left;

    .user-block {
      @apply items-start flex flex-col my-0 mx-2;
    }

    .user-name {
      @apply text-sm font-medium m-0 capitalize;
    }

    .view-details--button {
      @apply text-slate-600 dark:text-slate-200;
    }

    .user-email {
      @apply m-0;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }
  .ve-table-sort {
    @apply -top-1;
  }
}

.cell--social-profiles {
  a {
    @apply text-slate-300 dark:text-slate-400 text-lg min-w-[2rem] text-center;
  }
}
</style>
