export const getters = {
    getBotLearningTabs($state) {
        return $state.tabRecords;
    },
    getBotLearningItems($state) {
        return $state.itemRecords;
    },
    getUIFlags($state) {
        return $state.uiFlags;
    },
    getMeta: $state => {
        return $state.meta;
    },
};